import NavBar from "../navbar/NavBar";
import LogoSection from "../logosection/LogoSection";
import styles from './/Header.css'

const Header = () => {
    return (
        <div class="fixed-top">
            <div className="headerSection">
                <LogoSection />
                <NavBar />
            </div>
        </div>

    )
}

export default Header;