import Logo from '../../assets/Logo.png'
import styles from './/LogoSection.css';

const LogoSection = () => {
    return (
        <div className='logoSection'>
            <img src={Logo} height='60' alt='' />

            <div className='name' >
                <h3>Katherine Rock</h3>
            </div>
        </div>
    )
}

export default LogoSection;