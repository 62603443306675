import styles from './/Projects.css';
import WhitsundaysScreenshot from '../../assets/screenshot_whitsundays_home.png';
import PixeltasticScreenshot from '../../assets/Pixeltastic_screenshot.png';
import PowerBIScreenshot from '../../assets/PowerBI_Screenshot.png';
import CanvasAppScreenshot from '../../assets/CanvasApp_Screenshot.png';
import ModelDrivenAppScreenshot from '../../assets/ModelDrivenApp_Screenshot.png';
import WQvideo from '../../assets/Recording_WQ.mp4'

const Projects = () => {
    return (
        <div className="projectssection" id="projectssection">
            <h2 >Personal Projects</h2>

            {/*  Wine Quest - previously class card: style={{'maxWidth': '1000px'}}  */}
                <div class="card mb-5" >
                    <div class="row g-0">
                    <div class="col-md-4">
                        <video width="320" height="480" controls>
                            <source src={WQvideo} type="video/mp4" />
                        Your browser does not support the video tag.
                        </video>
                        {/* <!-- <img src="/images/placeholder_winequest.png" class="img-fluid rounded-start" alt="..."> --> */}
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                        <h5 class="card-title">Wine Quest</h5>
                        <p class="card-text">App for users to keep track of wines they have tasted and their rating of each wine.</p>
                        <p class="card-text">Users can sort by rating and date added, filter by wine type and search all key text fields.</p>
                        <p class="card-text">Users can see wines added by other users and their associated ratings.</p>
                        <p class="card-text">Users can track which wines they have in their cellar and the wines they plan to buy.</p>
                        <p class="card-text"><small class="text-body-secondary">React Native front end, Firebase back end</small></p>
                        </div>
                    </div>
                    </div>
                </div>

        <div className='verticalsection'>

            {/* Whitsundays -  previously card: style={{'width': '25vw'}} */}
            <div class="card" >
                <img src={WhitsundaysScreenshot} class="card-img-top" alt="..." />
                <div class="card-body">
                    <h5 class="card-title">'Whitsundays Sailing' travel planning app</h5>
                    <p class="card-text">App to share information, ideas, documents and photos for a group of people planning a holiday together.</p>
                    <p class="card-text"><small class="text-body-secondary">Ruby on Rails, Heroku, Amazon Web Services for image storage</small></p>
                    <a href="https://github.com/katherine-rock/whitsundays2021" class="btn btn-primary">GitHub &#8594;</a>
                </div>
            </div>

            {/* Pixeltastic  */}
            <div class="card" >
                <img src={PixeltasticScreenshot} class="card-img-top" alt="..." />
                <div class="card-body">
                    <h5 class="card-title">Pixeltastic</h5>
                    <p class="card-text">2-sided marketplace app for buying and selling stock images.</p>
                    <p class="card-text"><small class="text-body-secondary">Ruby on Rails, Heroku, Amazon Web Services for image storage, Stripe for processing payments</small></p>
                    <a href="https://pixeltastic.herokuapp.com" class="btn btn-primary">Pixeltastic &#8594;</a>
                </div>
            </div>


            {/* <!-- Card for SSR Power BI dashboards  --> */}

            <div class="card">
                <img src={PowerBIScreenshot} class="card-img-top" alt="..." />
                <div class="card-body">
                    <h5 class="card-title">Power BI dashboard</h5>
                    <p class="card-text">Dashboard showing pre-screening activities for a mock clinical trial. Power BI reports show pre-screening status across all sites, patient status at each site, reasons for screen failure and patients scheduled for consent.</p>
                    <p class="card-text"><small class="text-body-secondary">Power Platform - Power BI</small></p>
                </div>
            </div>

            {/* <!-- Card for SSR Power Apps - Canvas App - Protocol deviations --> */}

            <div class="card">
                <img src={CanvasAppScreenshot} class="card-img-top" alt="..." />
                <div class="card-body">
                    <h5 class="card-title">Protocol Deviation App - Canvas App</h5>
                    <p class="card-text">Protocol Deviation app provides a central location for CRAs and other project team members to enter, manage and view data related to project deviations for a mock clinical trial. The app includes automated notification to Project Managers and sponsors of major protocol deviations.</p>
                    <p class="card-text"><small class="text-body-secondary">Power Platform - Canvas App</small></p>
                </div>
            </div>

            {/* <!-- Card for SSR Power Apps - Model-driven App - Report-writing --> */}

            <div class="card" >
                <img src={ModelDrivenAppScreenshot} class="card-img-top" alt="..." />
                <div class="card-body">
                    <h5 class="card-title">Report App - Model-Driven App</h5>
                    <p class="card-text">App to draft, submit, review and approve monitoring visit reports for a mock clinical trial.</p>
                    <p class="card-text"><small class="text-body-secondary">Power Platform - Model-Driven App</small></p>
                </div>
            </div>
            </div>

        </div>
    )
}

export default Projects;