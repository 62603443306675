import Laptop_above from '../../assets/Laptop_above.jpeg';
import styles from './/WelcomeImage.css'

const WelcomeImage = () => {
    return (
        <div className='welcome' id="welcome" >
            <h1>Katherine Rock</h1>
            <p></p>
            <h2>Software Developer in Sydney, Australia</h2>
        </div>
    )
}

export default WelcomeImage