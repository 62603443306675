import './App.css';
// Custom components
import Header from '../header/Header';
import WelcomeImage from '../welcomeimage/WelcomeImage';
import AboutMe from '../aboutme/AboutMe';
import Projects from '../projects/Projects';
import Contact from '../contact/Contact';
import Footer from '../footer/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <header className="App-header">
        <WelcomeImage />
      </header>

      <AboutMe />
      <Projects />
      <Contact />
      <Footer />

    </div>
  );
}

export default App;
