import styles from './/NavBar.css'

const NavBar = () => {
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth'})
    }

    return (
        <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
            {/* <a class="navbar-brand" href="#">Navbar</a> */}
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">

            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <button onClick={handleScrollToTop} >Home</button>
                </li>

                <li class="nav-item">
                    <a href='/#aboutmesection'>
                        <button>About</button>
                    </a>
                </li>

                <li class="nav-item">
                    <a href='/#projectssection'>
                        <button>Projects</button>
                    </a>
                </li>

                <li class="nav-item">
                    <a href='/#contactsection'>
                        <button>Contact</button>
                    </a>
                </li>
            </ul>

            </div>
        </div>
        </nav>
    )
}

export default NavBar;